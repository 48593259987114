/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Architecture5 } from "../../icons/Architecture5";
import { Devops } from "../../icons/Devops";
import { Architecture } from "../Architecture";
import { Produit } from "../Produit";
import "./style.css";

interface Props {
  prop: "secudefaut" | "infrahover" | "devopdefaut" | "devopshover" | "secuhover" | "infrad-faut";
  className: any;
  produitPropertyDefaultClassName: any;
  produitPropertyDefaultClassNameOverride: any;
  architectureArchitectureClassName: any;
  architectureArchitecture: string;
  produitPropertyClassName: any;
  overlapGroupClassName: any;
  produitPropertyClassNameOverride: any;
  produitPropertyVariantClassName: any;
  t: any;
}

export const FicheProduit = ({
  prop,
  className,
  produitPropertyDefaultClassName,
  produitPropertyDefaultClassNameOverride,
  architectureArchitectureClassName,
  architectureArchitecture = "/img/architecture.svg",
  produitPropertyClassName,
  overlapGroupClassName,
  produitPropertyClassNameOverride,
  produitPropertyVariantClassName,
  t
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    prop: prop || "infrad-faut",
  });

  return (
    <div
      className={`fiche-produit ${state.prop} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {["infrad-faut", "infrahover"].includes(state.prop) && (
        <div className="overlap-group-3">
          {state.prop === "infrad-faut" && (
            <div className="frame-2">
              <div className="titre">{t('animated-squares.first-title')}</div>
              <p className="titre-2">{t('animated-squares.first-description')}</p>
            </div>
          )}

          {state.prop === "infrahover" && (
            <div className="frame-3">
              <div className="titre-3">{t('animated-squares.first-title')}</div>
              <p className="titre-2">{t('animated-squares.first-description')}</p>
            </div>
          )}

          {/* <Produit
            className={`${state.prop === "infrahover" ? "produit-instance" : produitPropertyClassName}`}
            property1="default"
          /> */}
          {state.prop === "infrahover" && <Architecture5 className="instance-node" />}
        </div>
      )}

      {["devopdefaut", "secudefaut"].includes(state.prop) && (
        <>
          <div className={`overlap-group-4 ${overlapGroupClassName}`}>
            <div className="frame-2">
              <div className="titre-3">
                {state.prop === "devopdefaut" && (
                  <>
                    {t('animated-squares.second-title')}
                  </>
                )}

                {state.prop === "secudefaut" && <>{t('animated-squares.third-title')}</>}
              </div>
              <div className="titre-2">
              {state.prop === "devopdefaut" && (
                  <>
                    {t('animated-squares.second-description')}
                  </>
                )}

                {state.prop === "secudefaut" && <>{t('animated-squares.third-description')}</>}
                 </div>
            </div>
            {/* {state.prop === "devopdefaut" && (
              <>
                <Produit className={produitPropertyDefaultClassName} property1="default" />
                <Produit className={produitPropertyDefaultClassNameOverride} property1="default" />
              </>
            )}

            {state.prop === "secudefaut" && (
              <div className="group-2">
                <Produit className={produitPropertyClassNameOverride} property1="default" />
                <Produit className={produitPropertyVariantClassName} property1="default" />
              </div>
            )} */}
          </div>
          {/* <Architecture architecture={architectureArchitecture} className={architectureArchitectureClassName} /> */}
        </>
      )}

      {["devopshover", "secuhover"].includes(state.prop) && (
        <>
          <div className="overlap-group-5">
            <div className="frame-4">
              <div className="titre-3">
                {state.prop === "devopshover" && (
                  <>
                    {t('animated-squares.second-title')}
                  </>
                )}

                {state.prop === "secuhover" && <>{t('animated-squares.third-title')}</>}
              </div>
              <div className="titre-2">
              {state.prop === "devopshover" && (
                  <>
                    {t('animated-squares.second-description')}
                  </>
                )}

                {state.prop === "secuhover" && <>{t('animated-squares.third-description')}</>}
                 </div>
            </div>
            {state.prop === "devopshover" && <Devops className="instance-node" />}

            {state.prop === "secuhover" && (
              <div className="secu">
                <div className="overlap-group-6">
                  <img className="vector" alt="Vector" src="/img/vector-41.svg" />
                  <img className="vector-2" alt="Vector" src="/img/vector-31.svg" />
                  <img className="vector-3" alt="Vector" src="/img/vector-39.svg" />
                  <img className="vector-4" alt="Vector" src="/img/vector-40.svg" />
                  <img className="exclude" alt="Exclude" src="/img/exclude-6.svg" />
                  <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-37.svg" />
                  <img className="vector-5" alt="Vector" src="/img/vector-43.svg" />
                </div>
              </div>
            )}
          </div>
          {/* <Produit className="produit-instance" property1="default" />
          <Produit className="produit-2" property1="default" /> */}
        </>
      )}
    </div>
  );
};

function reducer(state: any, action: any) {
  if (state.prop === "infrad-faut") {
    switch (action) {
      case "mouse_enter":
        return {
          prop: "infrahover",
        };
    }
  }

  if (state.prop === "infrahover") {
    switch (action) {
      case "mouse_leave":
        return {
          prop: "infrad-faut",
        };
    }
  }

  if (state.prop === "devopdefaut") {
    switch (action) {
      case "mouse_enter":
        return {
          prop: "devopshover",
        };
    }
  }

  if (state.prop === "devopshover") {
    switch (action) {
      case "mouse_leave":
        return {
          prop: "devopdefaut",
        };
    }
  }

  if (state.prop === "secudefaut") {
    switch (action) {
      case "mouse_enter":
        return {
          prop: "secuhover",
        };
    }
  }

  if (state.prop === "secuhover") {
    switch (action) {
      case "mouse_leave":
        return {
          prop: "secudefaut",
        };
    }
  }

  return state;
}

FicheProduit.propTypes = {
  prop: PropTypes.oneOf(["secudefaut", "infrahover", "devopdefaut", "devopshover", "secuhover", "infrad-faut"]),
  architectureArchitecture: PropTypes.string,
};
