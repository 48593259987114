/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Github1 } from "../../icons/Github1";
import { Zulip } from "../../icons/Zulip";
import { Discord } from "../Discord";
import { LinkedIn } from "../LinkedIn";
import "./style.css";

interface Props {
  className: any;
  maskGroup: string;
  t: any;
}

export const Footer = ({ className, maskGroup = "/img/mask-group-4.png", t }: Props): JSX.Element => {
  return (
    <div className={`footer ${className}`}>
      <div className="overlap">
        <div className="group">
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="ellipse" />
              <div className="div" />
              <div className="ellipse-2" />
              <div className="ellipse-3" />
              <img className="mask-group" alt="Mask group" src="/img/mask-group-5.png" />
              <img className="img" alt="Mask group" src={maskGroup} />
            </div>
          </div>
          <p className="dit-rit">
            <span className="text-wrapper">Dit</span>
            <span className="span">Rit</span>
          </p>
        </div>
        <p className="p">Design it, run it !</p>
      </div>
      <Github1 className="github" color="white" />
      <Discord className="discord-instance" />
      {/* <Zulip className="zulip-1" /> */}
      <a href="https://www.linkedin.com/company/ditrit/"><LinkedIn className="linked-in-instance" /></a>
      <img className="vector" alt="Vector" src="/img/vector-14-1.svg" />
      <p className="text-wrapper-2">© Copyright 2023 by DitRit</p>
      <a href="https://github.com/ditrit/OGrEE-Core/blob/main/CODE_OF_CONDUCT.md"><div className="text-wrapper-3">Code of Conduct</div></a>
      <a href="http://nextcloud.ditrit.io"><div className="text-wrapper-4">Nextcloud Storage</div></a>
      <a href="https://github.com/ditrit/OGrEE-Core/releases"><div className="text-wrapper-5">Releases</div></a>
      <a href="https://github.com/ditrit/OGrEE-Core/blob/main/LICENSE"><div className="text-wrapper-6">License</div></a>
      <div className="text-wrapper-7"></div>
      <div className="text-wrapper-8">{t('footer-title')}</div>
    </div>
  );
};

Footer.propTypes = {
  maskGroup: PropTypes.string,
};
