import React, { useEffect, useState } from "react";
import { Bouton } from "../../components/Bouton";
import { Footer } from "../../components/Footer";
import { ArrowRight3 } from "../../icons/ArrowRight3";
import { Github1 } from "../../icons/Github1";
import { Github6 } from "../../icons/Github6";
import "./style.css";
import { FicheProduit } from "../../components/FicheProduit";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useTranslation, Trans } from 'react-i18next';

const lngs = [
  {
    id: "en",
    nativeName: 'EN',
    flagimg: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
  },
  {
    id: "fr",
    nativeName: 'FR',
    flagimg: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"
  }
];


export const PageProduit = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const [cssDisplay, setCssDisplay] = useState('none');
  const initialLng = lngs.find((elem) => elem.id == i18n.language) ?? lngs[0];
  const [langFlag, setLangFlag] = useState(<img src={initialLng.flagimg} height="20" width="30" alt="nope" />)

  const showDropdown = () => {
    if (cssDisplay === 'none') {
      setCssDisplay('block');
    } else {
      setCssDisplay('none');
    }
  };

  const selectListItem = (event) => {
    handleLanguageChange(event);
    setCssDisplay('none');
    setLangFlag(<img src={event.target.src} height="20" width="30" alt="nope" />)
  };

  const handleLanguageChange = (event) => userLanguageChange(event);
  const userLanguageChange = (event) => {
    // Grab event.target.id and propagate lang change to app
    i18n.changeLanguage(event.target.id);
  }

  return (
    <div className="page-produit">
      <div className="div-2">

        <div className="navbar">
          <div className="language">
            <button style={{border: 'none', backgroundColor: 'transparent'}} onClick={showDropdown} >{langFlag}</button>
            <ul style={{ display: cssDisplay }}>
              {lngs.map((lang) => (
                <li id={lang.id} key={lang.id}>
                  <img onClick={selectListItem} src={lang.flagimg} height="20" width="30" alt="flagpic" id={lang.id} />
                </li>
              ))}
            </ul>
          </div>
          <img className="group-8" alt="Group" src="/img/group-27.svg" />
          <div className="text-wrapper-30">proudly presents:                </div>
          <a href="mailto:contact@ditrit.io"><div className="text-wrapper-32">Contact</div></a>
          <a href="https://github.com/ditrit"><Github6 className="github-6" color="#010517" /></a>
        </div>

        <div className="overlap-2">
          <div className="text-wrapper-20">Solutions</div>
          <p className="ogree-offline-graph">
            <span className="text-wrapper-16">
              OGrEE <br />
            </span>
            <span className="text-wrapper-17">{t('intro.subtitle')}</span>
          </p>

          <div className="overlap-3">
            <div className="ellipse-5" />
            <p className="text-wrapper-13">
              {t('intro.description')}
            </p>
            <div className="text-wrapper-15">{t('thumbnails.second-title')}</div>
            <div className="text-wrapper-14">{t('thumbnails.third-title')}</div>

            <img className="vector-2" alt="Vector" src="/img/vector.svg" />
            <img className="power" alt="Power" src="/img/power.svg" />
            <img className="vector-3" alt="Vector" src="/img/vector-69.svg" />
            <img className="vector-4" alt="Vector" src="/img/vector-63.svg" />
            <img className="vector-5" alt="Vector" src="/img/vector-64.svg" />
            <img className="vector-6" alt="Vector" src="/img/vector-52.svg" />
            <img className="vector-7" alt="Vector" src="/img/vector-62.svg" />
            <img className="vector-8" alt="Vector" src="/img/vector-49.svg" />
            <img className="vector-9" alt="Vector" src="/img/vector-50.svg" />
            <img className="vector-10" alt="Vector" src="/img/vector-51.svg" />
            <img className="vector-11" alt="Vector" src="/img/vector-53.svg" />
            <img className="group-2" alt="Group" src="/img/group-53.png" />
            <img className="vector-12" alt="Vector" src="/img/vector-57.svg" />
            <img className="vector-13" alt="Vector" src="/img/vector-58.svg" />
            <img className="vector-14" alt="Vector" src="/img/vector-59.svg" />
            <img className="vector-15" alt="Vector" src="/img/vector-60.svg" />
            <img className="vector-16" alt="Vector" src="/img/vector-61.svg" />
            <img className="vector-17" alt="Vector" src="/img/vector-48.svg" />
            <img className="vector-18" alt="Vector" src="/img/vector-47.svg" />
            <img className="vector-19" alt="Vector" src="/img/vector-73.svg" />
            <img className="vector-20" alt="Vector" src="/img/vector-74.svg" />
            <img className="group-3" alt="Group" src="/img/group-57.png" />
            <img className="vector-21" alt="Vector" src="/img/vector-69.svg" />
            <img className="group-4" alt="Group" src="/img/group-57.png" />
            <img className="vector-22" alt="Vector" src="/img/vector-70.svg" />
            <img className="group-5" alt="Group" src="/img/group-56.png" />
            <div className="overlap-wrapper">
              <div className="overlap-4">
                <div className="overlap-5">
                  <div className="rectangle" />
                  <div className="rectangle-2" />
                  <div className="rectangle-3" />
                  <div className="rectangle-4" />
                  <img className="check-circle" alt="Check circle" src="/img/check-circle.svg" />
                </div>
                <div className="overlap-6">
                  <img className="vector-23" alt="Vector" src="/img/vector-44.svg" />
                  <img className="vector-24" alt="Vector" src="/img/vector-45.svg" />
                  <img className="vector-25" alt="Vector" src="/img/vector-46.svg" />
                </div>
              </div>
            </div>
          </div>

          <Github1 className="github-1" color="#010517" />
          <Bouton className="bouton-1" divClassName="bouton-instance" text={t('github-button')} />
          <a href="https://github.com/ditrit/OGrEE-Core?tab=readme-ov-file#demo">
            <div className="frame">
              <div className="text-wrapper-19">{t('arrow-button')}</div>
              <ArrowRight3 className="arrow-right-3" />
            </div>
          </a>
          <div className="text-wrapper-18">{t('thumbnails.first-title')}</div>

          <div className="overlap-7">
            <div className="rectangle-5" />
            <div className="rectangle-6" />
            <div className="rectangle-7" />
            <img className="vector-26" alt="Vector" src="/img/vector-35.svg" />
            <img className="vector-27" alt="Vector" src="/img/vector-37.svg" />
            <img className="vector-28" alt="Vector" src="/img/vector-37.svg" />
            <img className="vector-29" alt="Vector" src="/img/vector-35.svg" />
          </div>
          <div className="overlap-8">
            <img className="eye" alt="Eye" src="/img/eye.svg" />
            <img className="vector-30" alt="Vector" src="/img/vector-39.svg" />
            <img className="vector-31" alt="Vector" src="/img/vector-40.svg" />
            <img className="vector-32" alt="Vector" src="/img/vector-38.svg" />
          </div>
        </div>

        <p className="text-wrapper-27">{t('thumbnails.first-subtitle')}</p>
        <p className="text-wrapper-28">{t('thumbnails.second-subtitle')}</p>
        <div className="text-wrapper-29">{t('thumbnails.third-subtitle')}</div>

        <div className="overlap-9">
          <div className="rectangle-8" />
          <FicheProduit
            architectureArchitecture="/img/architecture-1.png"
            architectureArchitectureClassName="fiche-produit-4"
            className="fiche-produit-instance"
            produitPropertyDefaultClassName="fiche-produit-2"
            produitPropertyDefaultClassNameOverride="fiche-produit-3"
            prop="devopdefaut"
            t={t}
          />
          <FicheProduit className="fiche-produit-5" produitPropertyClassName="fiche-produit-2" prop="infrad-faut" t={t} />
          <FicheProduit
            architectureArchitecture="/img/architecture-1.png"
            architectureArchitectureClassName="fiche-produit-10"
            className="fiche-produit-9"
            overlapGroupClassName="fiche-produit-6"
            produitPropertyClassNameOverride="fiche-produit-7"
            produitPropertyVariantClassName="fiche-produit-8"
            prop="secudefaut"
            t={t}
          />

          <div className="group-wrapper">
            <div className="group-6">
              <div className="text-wrapper-24">{t('carousel.title')}</div>
              <p className="OUI-notre-collectif">
                <span className="text-wrapper-26">
                  {t('carousel.description')}
                </span>
              </p>
              <div className="rectangle-9">
                <Carousel showThumbs={false} showStatus={false} dynamicHeight={false}>
                  <div>
                    <img height="300" src="/img/carousel/image1.png" />
                  </div>
                  <div>
                    <img height="300" src="/img/carousel/image2.png" />
                  </div>
                  <div>
                    <img height="300" src="/img/carousel/image3.png" />
                  </div>
                  <div>
                    <img height="300" src="/img/carousel/image4.png" />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>

          <div className="text-wrapper-22">{t('videos.title')}</div>
          <div className="text-wrapper-22-subtitle">{t('videos.subtitle1')}</div>
          <video className="carte-collab-5" width="1032" height="580" controls >
            <source src="/videos/video1.mp4" type="video/mp4" />
          </video>
          <div className="text-wrapper-22-subtitle-2">{t('videos.subtitle2')}</div>
          <video className="video-2" width="1032" height="580" controls >
            <source src="/videos/video2.mp4" type="video/mp4" />
          </video>
          <div className="text-wrapper-22-subtitle-3">{t('videos.subtitle3')}</div>
          <div className="text-wrapper-22-subtitle-4"><br/>{t('videos.subtitle4a')}<br/><br/>{t('videos.subtitle4b')}<a href="https://github.com/ditrit/OGrEE-Tools" style={{color: "blue"}}>OGrEE-Tools</a>{t('videos.subtitle4c')}</div>
          <video className="video-3" width="750" height="404" controls >
            <source src="/videos/video3.mp4" type="video/mp4" />
          </video>

          <Footer className="footer-instance" maskGroup="/img/mask-group-2.png" t={t} />
        </div>
      </div>
    </div>
  );
};
