/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Github6 = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`github-6 ${className}`}
      fill="none"
      height="31"
      viewBox="0 0 30 31"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_127_832)">
        <path
          className="path"
          clipRule="evenodd"
          d="M14.9553 0.941406C6.68541 0.941406 0 7.81641 0 16.3217C0 23.1205 4.28357 28.8755 10.226 30.9123C10.969 31.0655 11.2411 30.5814 11.2411 30.1742C11.2411 29.8177 11.2166 28.5955 11.2166 27.322C7.05643 28.2389 6.1901 25.4886 6.1901 25.4886C5.52153 23.7061 4.53092 23.248 4.53092 23.248C3.16929 22.3058 4.6301 22.3058 4.6301 22.3058C6.14051 22.4077 6.93306 23.8845 6.93306 23.8845C8.2699 26.227 10.4241 25.5652 11.2907 25.1577C11.4144 24.1645 11.8108 23.477 12.2317 23.0952C8.91367 22.7386 5.42265 21.4145 5.42265 15.5067C5.42265 13.8261 6.01653 12.4511 6.95755 11.3817C6.80908 10.9998 6.28898 9.42078 7.10633 7.30734C7.10633 7.30734 8.36908 6.89984 11.2163 8.88609C12.4353 8.54943 13.6925 8.37816 14.9553 8.37672C16.2181 8.37672 17.5053 8.55516 18.694 8.88609C21.5415 6.89984 22.8043 7.30734 22.8043 7.30734C23.6216 9.42078 23.1012 10.9998 22.9528 11.3817C23.9186 12.4511 24.488 13.8261 24.488 15.5067C24.488 21.4145 20.9969 22.713 17.6541 23.0952C18.199 23.5789 18.6692 24.4955 18.6692 25.947C18.6692 28.0095 18.6447 29.6648 18.6447 30.1739C18.6447 30.5814 18.9171 31.0655 19.6598 30.9127C25.6022 28.8752 29.8858 23.1205 29.8858 16.3217C29.9103 7.81641 23.2004 0.941406 14.9553 0.941406Z"
          fill={color}
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_127_832">
          <rect className="rect" fill="white" height="30" transform="translate(0 0.941406)" width="30" />
        </clipPath>
      </defs>
    </svg>
  );
};

Github6.propTypes = {
  color: PropTypes.string,
};
