/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  className: any;
  divClassName: any;
  text: string;
}

export const Bouton = ({ className, divClassName, text = "En savoir plus" }: Props): JSX.Element => {
  return (
    <a href="https://github.com/ditrit/OGrEE-Core">
      <div className={`bouton ${className}`}>
        <div className={`en-savoir-plus ${divClassName}`}>{text}</div>
      </div>
    </a>
  );
};

Bouton.propTypes = {
  text: PropTypes.string,
};
