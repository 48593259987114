/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const ArrowRight3 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`arrow-right-3 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 21 22"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5 9.5H19"
        stroke="#2E71E5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M12 2.5L19 9.5L12 16.5"
        stroke="#2E71E5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
