/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Architecture5 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`architecture-5 ${className}`}
      fill="none"
      height="198"
      viewBox="0 0 392 198"
      width="392"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_26_157)">
        <path
          className="path"
          d="M12.1021 98.453C-0.0902113 107.606 -57.934 125.362 -85.3318 133.096L-8.52613 193.467C27.9467 180.404 82.169 137.272 104.721 117.34C134.014 120.23 154.751 112.905 172.434 103.293C190.118 93.6805 191.022 84.8351 188.266 77.0582C185.511 69.2813 176.359 70.0461 167.212 74.6235C158.065 79.2009 134.293 99.4896 132.427 71.4297C130.562 43.3699 156.386 44.5144 162.5 46C178.034 49.7747 188.608 65.3543 195 53C201.392 40.6457 194.987 33.5925 181.5 25.5C166.5 16.5 149.753 19.0155 142.431 19.8816C146.851 16.5254 157.276 8.71466 163.616 4.32102C171.542 -1.17104 179.77 -8.34076 176.25 -17.6425C173.434 -25.0839 163.073 -23.3834 158.245 -21.603C158.36 -26.8491 155.539 -30.2949 154.114 -31.3621C145.069 -42.7059 129.4 -32.7283 122.696 -26.3215C115.362 -35.6223 98.8999 -25.0955 81.5621 17.1519C64.2692 59.29 27.5333 86.8685 12.2209 98.3639L12.1021 98.453Z"
          fill="#2E71E5"
        />
        <path
          className="path"
          d="M-0.855572 193.603C22.9733 182.604 68.3261 158.838 102.177 124.851C111.186 126.843 134.295 128.363 154.658 118.508C180.111 106.189 192.363 93.3252 185.089 84.2429C168.81 63.9148 139.486 111.997 129.841 82.8087C123.996 65.119 140.713 47.2287 157.476 48.5C176.389 49.9344 186.176 65.7468 194 54.5C200.5 45.1562 193.713 33.4503 172.938 25.5C156.438 19.1858 151.793 20.4005 146 21M113.849 36.6012C118.84 33.2776 134.992 22.1391 146 21M-88.9997 140.49C-62.5171 132.745 -3.99926 116.112 12.9985 103.714C71.8952 60.7569 83.4028 7.14583 90.7024 -2.60217C98.002 -12.3502 110.776 -29.4091 121.184 -17.8119M99.2752 7.62102C106.876 -3.80348 125.129 -26.649 137.337 -26.6353C152.598 -26.6181 155.366 -18.8405 154.463 -13.811M120.497 11.1511C133.126 -3.16416 165.723 -26.2985 172.938 -10.4365C180.152 5.42548 162.166 5.78587 146 21"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          className="path"
          d="M179.065 78.8172C177.465 76.0172 177.565 67.3173 180.065 62.3173C183.565 53.8173 239.566 60.3172 243.066 61.3172C246.566 62.3172 248.566 78.8172 243.066 80.8172C237.566 82.8172 181.065 82.3172 179.065 78.8172Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          className="path"
          d="M346.416 63.8172C348.016 61.0172 347.916 52.3173 345.416 47.3173C341.916 38.8173 285.916 45.3172 282.416 46.3172C278.916 47.3172 276.916 63.8172 282.416 65.8172C287.916 67.8172 344.416 67.3172 346.416 63.8172Z"
          fill="#2E71E5"
        />
        <path
          className="path"
          d="M341.416 68.8172C343.016 66.0172 342.916 57.3173 340.416 52.3173C336.916 43.8173 280.916 50.3172 277.416 51.3172C273.916 52.3172 271.916 68.8172 277.416 70.8172C282.916 72.8172 339.416 72.3172 341.416 68.8172Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          className="path"
          d="M440.942 50.8641C442.531 44.5607 442.432 24.9752 439.949 13.7191C436.474 -5.41624 380.867 9.21659 377.392 11.4678C373.916 13.719 371.93 50.8641 377.392 55.3666C382.853 59.869 438.956 58.7434 440.942 50.8641Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
        />
        <path
          className="path"
          d="M338.942 113.4C340.531 119.937 340.432 140.248 337.949 151.921C334.474 171.765 278.867 156.59 275.392 154.256C271.916 151.921 269.93 113.4 275.392 108.731C280.853 104.062 336.956 105.229 338.942 113.4Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          className="path"
          d="M343.942 109.4C345.531 115.937 345.432 136.248 342.949 147.921C339.474 167.765 283.867 152.59 280.392 150.256C276.916 147.921 274.93 109.4 280.392 104.731C285.853 100.062 341.956 101.229 343.942 109.4Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
        />
        <path
          className="path"
          d="M308.001 72.5001C307.334 77.8334 306.401 91.9001 308.001 105.5M274.501 61.5001C264.834 59.5001 257.1 69.3001 247.5 72.5001M308.001 47.5C308.001 41.3334 308.001 27.3 308.001 20.5C308.001 12 370 16.5 373.5 18.5"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_26_157">
          <rect className="rect" fill="white" height="288" transform="translate(0 -90)" width="392" />
        </clipPath>
      </defs>
    </svg>
  );
};
